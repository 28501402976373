.App {
    height: 100vh;
    width: 100vw;
    background: #eeeeee;
    display: grid;
    grid-template-columns: repeat(48, 1fr);
    grid-template-rows: repeat(25, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: #333333;
    overflow-y: hidden !important;
}

.LoginContainer {
    grid-area: 5 / 19 / 20 / 31;
    background: rgb(221,221,221);
    background: linear-gradient(180deg, rgba(221,221,221,1) 0%, rgba(187,187,187,1) 100%);
    border: 1.5px solid #888888;
    border-radius: .5rem;
    box-shadow: 0px 3px 5px 0px gray;
    
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(15, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.InputNoAppend {
    border-radius: .5rem;
    border: 1.5px solid #888888;
    padding: 1vmin;
    padding-left: 2vmin;
    font-size: 2.5vmin;
    color: #333333;
}
.InputNoAppend:hover {
    outline: 0;
    border: 1.5px solid rgb(115, 144, 182);
}
.InputNoAppend:focus {
    outline: 0;
    border: 1.5px solid #4C6A91;
}

.Button {
    font-size: 2vh;
    opacity: 1;
    border-radius: .5rem;
    border: .5px solid #888888;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: rgb(76,106,145);
    background-image: linear-gradient(90deg, rgba(46,76,115,1) 0%, rgba(106,136,175,1) 100%);
    background-size: 300% 300%;
    background-position: left;
    transition: background-position .5s, color 1s;
}
.Button:hover {
    background-position: right;
    color: #f9d77e;
}
.Button:focus {
    outline: 0;
    border-color: #7f00ff;
    box-shadow: 0 0 5px 2px #7f00ff;
    
}
.Button:disabled {
    color: gray;
    opacity: .75;
    pointer-events: none;
}

.Shadow {
    box-shadow: 0px 1px 3px 0px black;
}
